import React from 'react'
import styled from 'styled-components'
import Img from '../components/Img'
import Breadcrumbs from './Breadcrumbs'

const HeroPage = ({ heroTitle, heroImage, variant, article = false, breadcrumbs }) => {

  return (
    <HeroPageBlock article={ article } variant={ variant }>
      { heroImage
        ? <Img styles="HeroPage__img" localFile={ heroImage.node.localFile } alt={ heroTitle } />
        : null
      }
      <h1 className="HeroPage__title">{ heroTitle }</h1>
      <Breadcrumbs breadcrumbs={ breadcrumbs } variant="page"/>
    </HeroPageBlock>
  )
}

export default HeroPage

const HeroPageBlock = styled.div `

  position: relative;
  margin-bottom: ${props => props.variant === 'companies' ? '0' : '2rem'};
  max-height: 400px;
  max-width: 1500px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  border-radius: ${props => props.theme.borderRadius};
  overflow: hidden;

  @media only screen and (max-width: 1600px) {
    border-radius: 0;
  }

  @media only screen and (max-width: 480px) {
    margin-bottom: ${props => props.variant === 'companies' ? '0' : '1rem'};;
  }

  .HeroPage__title {
    position: absolute;
    top: 50%;
    left: ${props => props.article ? '47rem' : '50%'};
    transform: translate(-50%, -50%);
    margin-bottom: 0;
    font-size: 4.6rem;
    text-align: ${props => props.article ? 'left' : 'center'};
    text-transform: ${props => props.article ? 'none' : 'uppercase'};
    color: ${props => props.theme.white};

    @media only screen and (max-width: 480px) {
      font-size: 3.2rem;
      line-height: 1.3;
    }
  }

  .HeroPage__img {
    position: relative;

    @media only screen and (max-width: 1280px) {
      min-height: 400px;
    }

    @media only screen and (max-width: 1080px) {
      min-height: 340px;
    }

    @media only screen and (max-width: 480px) {
      min-height: 260px;
    }

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      width: 100%;
      height: 100%;
      background: #031421;
      mix-blend-mode: normal;
      opacity: 0.6;
    }
  }
`