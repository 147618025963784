import { useStaticQuery, graphql } from "gatsby"

export const useCompaniesQuery = () => {
  const data = useStaticQuery(graphql `
    query CompaniesQuery {
      allWpCompany {
        nodes {
          id
          title
          uri
          excerpt
          companyFields {
            companyAddress
            companyPhone
            companySite
            companyTiker
          }
          sectors {
            nodes {
              name
              slug
            }
          }
          countries {
            nodes {
              name
            }
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 100, height: 100, placeholder: BLURRED, quality: 100)
                }
              }
            }
          }
        }
      }
    }
  `)
  return data
}