import React from 'react'
import { Link } from 'gatsby'
import parse from 'html-react-parser'
import styled from 'styled-components'
import Img from '../Img'

const ListPosts = ({ posts }) => {

  console.log(posts)

  const getCatogory = (nodes) => (nodes.filter(node => node.name !== 'Все посты')[0].name)

  return (
    <ListPostsBlock>
      { posts.map(post => (
        <Link className="ListBlockPost__item" key={ post.node.id } to={ post.node.uri }>
          <Img styles="ListBlockPost__img" localFile={ post.node.postThumb.postThumb?.localFile } alt={ post.node.title } />
          <div className="ListBlockPost__content">
            <small className="ListBlockPost__small">{ getCatogory(post.node.categories.nodes) }</small>
            <h4 className="ListBlockPost__title">{ post.node.title }</h4>
            { parse(post.node.excerpt) }
            {/* <Link className="ListBlockPost__link" to={ post.node.uri }>Читать далее...</Link> */}
          </div>
        </Link>
      )) }
    </ListPostsBlock>
  )
}

export default ListPosts

const ListPostsBlock = styled.div `

  flex: 1;
  width: 60%;
  padding: 4rem;
  margin-right: 2rem;
  border-radius: ${props => props.theme.borderRadius};
  background-color: ${props => props.theme.white};
  box-shadow: ${props => props.theme.shadowBlock};

  @media only screen and (max-width: 1080px) {
    padding: 3rem;
  }

  @media only screen and (max-width: 980px) {
    padding: 2rem;
  }

  @media only screen and (max-width: 480px) {
    margin-left: 0;
    width: 100%;
  }

  * + .ListBlockPost__item {
    display: block;
    margin-top: 3rem;

    @media only screen and (max-width: 480px) {
      margin-top: 2.8rem;
    }
  }

  .ListBlockPost__item {
    display: flex;
    justify-content: flex-start;

    &:hover {

      .ListBlockPost__title {
        color: ${props => props.theme.blue};
      }

      .ListBlockPost__small {
        background-color: ${props => props.theme.black};
        color: ${props => props.theme.white};
      }
    }

    @media only screen and (max-width: 980px) {
      flex-direction: column;
    }
  }

  .ListBlockPost__img {
    flex: 1 0 300px;
    margin-right: 3rem;

    @media only screen and (max-width: 980px) {
      margin-right: 0;
      margin-bottom: 2rem;
    }

    @media only screen and (max-width: 480px) {
      flex: 1 0 220px;
      margin-bottom: 1rem;
    }
  }

  .ListBlockPost__content {
    flex: 1 1 70%;

    p {
      margin-top: 1.2rem;
      font-size: 1.5rem;
      letter-spacing: 0;
      color: ${props => props.theme.black};

      @media only screen and (max-width: 480px) {
        margin-top: 1.4rem;
        font-size: 1.5rem;
        line-height: 1.6;
      }
    }
  }

  .ListBlockPost__small {
    display: inline-block;
    padding: 0.5rem;
    margin-bottom: 1rem;
    background-color: ${props => props.theme.skyExtraLight};
    color: ${props => props.theme.blueGray};
    transition: all ease 0.2s;

    @media only screen and (max-width: 480px) {
      margin-bottom: 1rem;
    }
  }

  .ListBlockPost__title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.3;
    letter-spacing: 0;
    color: ${props => props.theme.black};
    transition: all ease 0.2s;
  }

  .ListBlockPost__link {
    font-size: 1.5rem;
    font-weight: 500;
    letter-spacing: 0;
    color: ${props => props.theme.blue};
    transition: all 0.2s ease-in;

    &:hover {
      color: ${props => props.theme.blueGray};
    }
  }

`
