import React from 'react'
import { graphql } from 'gatsby'
import { usePostsQuery } from '../hooks/usePostsQuery'
import { useCompaniesQuery } from '../hooks/useCompaniesQuery'
import Layout from '../components/Layout/Layout'
import Container from '../components/Container'
import HeroPage from '../components/HeroPage'
import ListPosts from '../components/Lists/ListPosts'
import SideBar from '../components/SideBar/SideBar'
import SideBarContent from '../components/SideBar/SideBarContent'
import CompaniesList from '../components/Company/CompaniesList'




const PageTemplate = ({ data }) => {

  const { title, seo } = data.wpPage

  const getLocalFile = data.wpPage.featuredImage

  let posts = null
  let companies = null

  const getPosts = usePostsQuery()
  const getCompanies = useCompaniesQuery()

  switch (title) {

    case 'Инвестиции':
      posts = [...getPosts.allWpStock.edges, ...getPosts.allWpEstate.edges]
      break;

    case 'Обзор компаний':
      companies = getCompanies.allWpCompany.nodes
      break;

    case 'Фондовый рынок':
      posts = getPosts.allWpStock.edges
      break;

    case 'Недвижимость':
      posts = getPosts.allWpEstate.edges
      break;

    case 'Криптовалюты':
      posts = getPosts.allWpCryptocurrency.edges
      break;

    case 'Трейдинг':
      posts = getPosts.allWpTrading.edges
      break;

    case 'Форекс':
      posts = getPosts.allWpForex.edges
      break;

    case 'Новичкам':
      posts = getPosts.allWpNewbie.edges
      break;

    default:
      break;
  }

  return (
    <Layout seo={ seo }>
      <HeroPage heroTitle={ title } heroImage={ getLocalFile } breadcrumbs={ seo.breadcrumbs } />
      <Container variant="aside">
        { posts &&
          <>
            <ListPosts posts={ posts } />
            <SideBar>
              <SideBarContent title="Популярное">
              </SideBarContent>
            </SideBar>
          </>
        }
        { companies && <CompaniesList companies={ companies } /> }
      </Container>
    </Layout>
  )
}

export default PageTemplate

export const PageQuery = graphql `
  query($id: String!) {
    wpPage(id: { eq: $id }) {
      id
      title
      status
      content
      featuredImage {
        node {
          id
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                width: 1600
                height: 460
                jpgOptions: {progressive: true}
                webpOptions: {quality: 70}
              )
            }
          }
        }
      }
      seo {
        breadcrumbs {
          text
          url
        }
        canonical
        metaDesc
        opengraphDescription
        opengraphModifiedTime
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        readingTime
        schema {
          articleType
          pageType
          raw
        }
        title
        twitterDescription
        twitterTitle
      }
    }
  }
`
