import { useStaticQuery, graphql } from "gatsby"

export const usePostsQuery = () => {
  const data = useStaticQuery(graphql `
    query PostQuery {
      allWpStock(sort: {fields: date, order: DESC}) {
        edges {
          node {
            id
            title
            uri
            excerpt
            categories {
              nodes {
                name
              }
            }
            postThumb {
              postThumb {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 700, height: 500, placeholder: BLURRED, quality: 100)
                  }
                }
              }
            }
          }
        }
      }
      allWpEstate(sort: {fields: date, order: DESC}) {
        edges {
          node {
            id
            title
            uri
            excerpt
            categories {
              nodes {
                name
              }
            }
            postThumb {
              postThumb {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 700, height: 500, placeholder: BLURRED, quality: 100)
                  }
                }
              }
            }
          }
        }
      }
      allWpCryptocurrency(sort: {fields: date, order: DESC}) {
        edges {
          node {
            id
            title
            uri
            excerpt
            categories {
              nodes {
                name
              }
            }
            postThumb {
              postThumb {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 700, height: 500, placeholder: BLURRED, quality: 100)
                  }
                }
              }
            }
          }
        }
      }
      allWpTrading(sort: {fields: date, order: DESC}) {
        edges {
          node {
            id
            title
            uri
            excerpt
            categories {
              nodes {
                name
              }
            }
            postThumb {
              postThumb {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 700, height: 500, placeholder: BLURRED, quality: 100)
                  }
                }
              }
            }
          }
        }
      }
      allWpForex(sort: {fields: date, order: DESC}) {
        edges {
          node {
            id
            title
            uri
            excerpt
            categories {
              nodes {
                name
              }
            }
            postThumb {
              postThumb {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 700, height: 500, placeholder: BLURRED, quality: 100)
                  }
                }
              }
            }
          }
        }
      }
      allWpNewbie(sort: {fields: date, order: DESC}) {
        edges {
          node {
            id
            title
            uri
            excerpt
            categories {
              nodes {
                name
              }
            }
            postThumb {
              postThumb {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 700, height: 500, placeholder: BLURRED, quality: 100)
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  return data
}